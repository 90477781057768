.effectShadow {
    box-shadow: 3px 10px 9px gray;
}

.effectShadowLight {
    box-shadow: 3px 6px 6px gray;
}

.effectShadowStrong {
    box-shadow: 3px 12px 11px black;
}

.effectShadow-success {
    box-shadow: 3px 10px 9px #159776;
}

.effectShadowLight-success {
    box-shadow: 3px 6px 6px #159776;
}

.effectShadowStrong-success {
    box-shadow: 3px 12px 11px #159776;
}

.effectShadowLight-silver {
    box-shadow: 3px 3px 3px silver;
}

.effectShadowStrong-silver {
    box-shadow: 3px 6px 6px silver;
}

.dremi-progress-overlay {
    background: #fff !important;
}

.border-1 {
    border-width: 1px !important;
}

.border-2 {
    border-width: 2px !important;
}

.border-3 {
    border-width: 3px !important;
}

.border-4 {
    border-width: 4px !important;
}

.text-weight-bold1 {
    font-weight: 500 !important;
}

.text-weight-bold2 {
    font-weight: bold !important;
}

.text-white-shadow {
    color: #fff !important;
    text-shadow: 0 2px 8px #000;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

.divCenterParent {
    float: left;
    position: relative;
    left: 50%;
}

.divCenterChild {
    float: left;
    position: relative;
    left: -50%;
}

.noScroll::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.z2000 {
    z-index: 2000;
}

.z1000 {
    z-index: 1000;
}

.zUnset {
    z-index: unset;
}

.center-relative-x {
    left: 50%;
    transform: translateX(-50%);
}

.overlay-container {
    display: grid !important;
}

.overlay-content,
.overlay {
    grid-area: 1 / 1 !important;
}

.blur15 {
    filter: blur(15px);
}

.float-left {
    float: left;
}

.blurPrivacy {
    color: transparent;
    /* Makes text itself invisible */
    filter: blur(3px);
    /* Blurs the entire text */
    // text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    /* Adds a glow-like effect */
    // background-image: repeating-linear-gradient(45deg,
    //         #f49f0a,
    //         #ffdcad 10px,
    //         #2a2a72 10px,
    //         #e0e0e0 20px);
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 2rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 2rem !important;
}