.m-ios-bottom {
    margin-bottom: env(safe-area-inset-bottom);
}

@supports (margin: env(safe-area-inset-bottom)) {
    .m-ios-bottom {
        /* Use the modern syntax */
        margin-bottom: env(safe-area-inset-bottom);
    }
}

@supports not (margin: env(safe-area-inset-bottom)) {
    .m-ios-bottom {
        /* Fallback for older iOS versions */
        margin-bottom: constant(safe-area-inset-bottom);
    }
}