.bg-gradient-00 {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}

.bg-gradient-01 {
    background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
}

.bg-gradient-02 {
    background: linear-gradient(135deg, #5ee7df 0%, #b490ca 100%);
}

.bg-gradient-03 {
    background: linear-gradient(135deg, #c3cfe2 0%, #c3cfe2 100%);
}

.bg-gradient-04 {
    background: linear-gradient(135deg, #ffafbd 0%, #ffc3a0 100%);
}

.bg-gradient-05 {
    background: linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%);
}

.bg-gradient-06 {
    background: linear-gradient(135deg, #cc2b5e 0%, #753a88 100%);
}

.bg-gradient-07 {
    background: linear-gradient(135deg, #56ab2f 0%, #a8e063 100%);
}

.bg-gradient-08 {
    background: linear-gradient(135deg, #eacda3 0%, #d6ae7b 100%);
}

.bg-gradient-09 {
    background: linear-gradient(135deg, #ED242C 0%, #D2218B 100%);
}

.bg-gradient-bee-diagonal {
    background: linear-gradient(60.95deg, #ed242c 0%, #d2218b 100%) !important;
}

.bg-gradient-bee-vertical {
    background: linear-gradient(180deg, #ed242c 0%, #d2218b 100%);
}

.bg-gradient-bee-vertical-shadow {
    background: linear-gradient(180deg, #F1BEDD 0%, #FBE9F4 50%, #ffffff 100%);
}

.bg-gradient-bee-vertical-shadow-inverse {
    background: linear-gradient(180deg, #ffffff 0%, #FBE9F4 50%, #F1BEDD 100%);
}

.bg-gradient-bee2-diagonal {
    background: linear-gradient(60.95deg, #8924ed 0%, #ed24a0 100%) !important;
}

.bg-gradient-bee2-vertical {
    background: linear-gradient(180deg, #8924ed 0%, #ed24a0 100%);
}

.bg-gradient-gold {
    background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #fff 0, #ffffac 8%, gold 25%, gold 62.5%, #5d4a1f 100%) !important;
}

.bg-gradient-classnow {
    // background: radial-gradient(circle, #f49f0a 0%, #d9781e 40%, #a24936 100%);
    background: linear-gradient(135deg, #f49f0a 0%, #a24936 100%);
}